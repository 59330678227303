<style lang="scss" scoped>
.clip {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(#000000, 0.8);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  padding: 50% 0;

  .nav-text {
    margin: 12px 0;
    opacity: 0.8;
    font-family: "adobe-handwriting-ernie", serif;
  }

  .nav-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: auto;
    .nav-link {
      color: #ffffff;
      font-size: 1.5rem;
      text-decoration: none;
      text-transform: uppercase;
      font-family: "cheap-pine-sans", sans-serif;
      padding: 8px 0;
      &.active {
        &:before {
          display: none;
        }
        color: $primary;
      }
    }
  }

  .social-links {
    display: flex;
    .social-link {
      color: inherit;
      //text-indent: -99999px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>

<template>
  <transition
    @before-enter="fadeBeforeEnter"
    @enter="fadeEnter"
    @leave="fadeLeave"
  >
    <div
      class="clip"
      v-show="$store.getters['nav/getNav']"
      @click.self="$store.commit('nav/CLOSE_NAV')"
    >
      <ul class="nav-menu">
        <nav-link style="display: flex" index="1">Patrol</nav-link>
        <nav-link style="display: flex" index="2">Food</nav-link>
        <nav-link style="display: flex" index="3">Drinks</nav-link>
        <nav-link style="display: flex" index="4">Place</nav-link>
        <nav-link style="display: flex" :to="{ name: 'Booking' }"
          >Booking</nav-link
        >
        <nav-link style="display: flex" :to="{ name: 'Booking' }"
          >Delivery</nav-link
        >
        <nav-link style="display: flex" index="5">Contact</nav-link>
      </ul>
      <span class="nav-text">Stay tuned !</span>
      <div class="social-links">
        <a
          href="https://www.instagram.com/patrol_paris"
          target="_blank"
          rel="noopener"
          class="social-link hide-text"
        >
          <icon-instagram />
        </a>
        <a
          href="https://www.facebook.com/patrolparis"
          target="_blank"
          rel="noopener"
          class="social-link hide-text"
        >
          <icon-facebook />
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { fade } from "@/js/gsap-transitions-hooks";
import NavLink from "@/views/nav/NavLink";

export default {
  name: "NavMenu",
  components: { NavLink },
  methods: {
    ...fade
  }
};
</script>
