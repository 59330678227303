<style lang="scss" scoped>
.nav-link {
  padding: 0 12px;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-family: "cheap-pine-sans", sans-serif;
  text-decoration: none;
  color: inherit;
  height: inherit;
  display: none;
  align-items: center;
  transition: 250ms ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  @media screen and (min-device-width: 800px) {
    display: flex;
  }
  &.menu {
    display: flex;
  }
  a {
    display: flex;
    height: inherit;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }

  &:before {
    content: "";
    background-color: $secondary-dark;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -100%;
    border-radius: 1000px;
    transform: scale(0);
    transition: 250ms ease-out;
    z-index: -1;
  }

  &:hover,
  &:active,
  &.active,
  &.router-link-active {
    &:before {
      opacity: 1;
      transform: scale(2);
    }
    color: white;
  }
}
</style>

<template>
  <template v-if="index">
    <li
      class="nav-link"
      :class="
        $store.getters['swiper/getIndex'] === parseInt(index) ? 'active' : ''
      "
      @click.prevent="$store.dispatch('swiper/slideTo', index)"
    >
      <slot />
    </li>
  </template>
  <template v-else-if="to">
    <li class="nav-link">
      <router-link :to="to" :class="menu ? 'menu' : ''">
        <slot />
      </router-link>
    </li>
  </template>
  <template v-else-if="href">
    <li class="nav-link">
      <a :href="href" target="_blank" rel="noopener"><slot /></a>
    </li>
  </template>
</template>

<script>
export default {
  name: "NavLink",
  props: {
    index: {
      type: String,
      default: ""
    },
    to: {
      type: [Object, String],
      default: ""
    },
    href: {
      type: String,
      default: ""
    },
    menu: {
      type: Boolean,
      default: false
    }
  }
};
</script>
